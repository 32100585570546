/* ------------- PADDING CLASSES ------------- */

/* Generic */
.padding-0 {
  padding: 0px !important;
}

.padding-24 {
  padding: 24px !important;
}

/* Top */
.padding-top-2 {
  padding-top: 2px;
}

.padding-top-5 {
  padding-top: 5px;
}
.padding-top-6 {
  padding-top: 6px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-12 {
  padding-top: 12px;
}

.padding-top-14 {
  padding-top: 14px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-17 {
  padding-top: 17px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

/* Right */
.padding-right-5 {
  padding-right: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-23 {
  padding-right: 23px !important;
}

.padding-right-28 {
  padding-right: 28px !important;
}

.padding-right-45 {
  padding-right: 45px !important;
}

/* Bottom */
.padding-bottom-1 {
  padding-bottom: 1px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

/* Left */
.padding-left-1 {
  padding-left: 1px;
}

.padding-left-2 {
  padding-left: 2px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-left-100 {
  padding-left: 100px;
}

/* ------------- MARGIN CLASSES ------------- */

/* Generic */
.margin-0 {
  margin: 0 !important;
}

/* Top */
.margin-top-minus-14 {
  margin-top: -14px;
}

.margin-top-3 {
  margin-top: 3px;
}
.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

/* Right */
.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-30 {
  margin-right: 30px;
}

/* Bottom */
.margin-bottom-3 {
  margin-bottom: 3px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

/* Left */
.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}
