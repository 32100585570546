/* custom table */
.mat-row.ng-star-inserted:nth-child(2n) {
  background-color: #efefef;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted:nth-child(2n):hover,
.mat-row.ng-star-inserted:hover {
  background-color: #b6c5d5;
  border-bottom: 1px solid transparent;
}

.mat-row.ng-star-inserted {
  background-color: white;
}

.mat-table {
  font-family: 'Titillium Web', sans-serif !important;
  min-width: 100%;
  max-width: fit-content;
}

.table-outer {
  max-height: 90vh;
  padding-bottom: 40px;
  overflow: hidden;
}

.table-container mat-table {
  min-width: 100%;
  width: fit-content !important;
}

.table-container {
  max-height: calc(80vh - 80px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.mat-header-cell {
  font-weight: bold !important;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 60px;
}

mat-cell {
  user-select: text !important;
}

.mat-cell {
  padding-right: 10px;
  padding-left: 10px;
  min-width: 60px;
  white-space: nowrap !important;
}

.mat-cell p {
  white-space: nowrap !important;
  padding-top: 0.6em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.button-column {
  max-width: 100px !important;
  justify-content: center;
}

.value-column {
  min-width: 130px;
  max-width: 130px;
  display: flex;
  justify-content: flex-end;
}

.boolean-column {
  min-width: 90px;
  max-width: 90px;
  text-align: center;
}

.boolean-column p {
  width: 100%;
}

.datetime-column {
  min-width: 180px;
  max-width: 180px;
}

.id-column {
  min-width: 100px;
  max-width: 100px;
}

.barcode-column {
  min-width: 150px;
  max-width: 150px;
}

.supplier-code-column {
  min-width: 80px;
  max-width: 80px;
}

.supplier-sku-column {
  min-width: 130px;
  max-width: 130px;
}

.barcode-type-column {
  min-width: 70px;
  max-width: 70px;
}

.supplier-name-column {
  min-width: 170px;
}

.product-title-column {
  min-width: 250px;
  max-width: 250px;
}

.sku-column {
  min-width: 120px;
  max-width: 120px;
}
