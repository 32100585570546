.parent-history-modal-component {
  max-height: 80vh;
  width: 85vw;

  mat-dialog-container {
    margin: 0 !important;
    padding: 0;

    .mat-dialog-title {
      margin: 0 !important;
    }

    .mat-dialog-content {
      margin: 0 !important;
      max-height: unset !important;
    }

    .mat-tab-body-content {
      overflow: hidden;
    }
  }
}

.mat-list-item {
  font-family: 'Titillium Web', sans-serif !important;
}

.blacklist-stepper {
  .mat-horizontal-stepper-header {
    height: 50px !important;
  }
}

.expanding-menu {
  margin: 0px 13px 0 !important;
  font-size: 0.75rem;
}

.blacklist-reasons-list {
  margin-top: 5px;
  background-color: whitesmoke;
  max-height: 150px;
  overflow-y: scroll;
}

.selectable-chip {
  text-align: center;
  display: inline-block;
  background-color: whitesmoke;
  border: solid 1px $primary;
  padding: 4px;
  margin: 4px;
  min-width: 200px;
  border-radius: 8px;
  cursor: pointer;
  b {
    color: $primary;
  }
  &:hover {
    background-color: #ebebeb;
  }
  &.selected {
    background-color: #dadada;
    color: $primary;
    border: solid 1px $primary;
  }
}

.squared-button {
  border-radius: 0;
}
