@import 'import/margins.css';
@import 'import/table-styles.css';

@import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.min.css';
@import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.min.css';

@import 'import/flags';

// MDB SCSS
$primary: #00275c;
$secondary: #b5985a;
@import '../../../node_modules/mdb-angular-ui-kit/assets/scss/mdb.scss';

// CURRENCY FLAG ICONS
@import '../../../node_modules/currency-flags/dist/currency-flags.min.css';

@import 'import/z-index';

body {
  font-family: 'Titillium Web', sans-serif !important;
  position: relative;
  min-height: 100%;
  height: 100vh;
  font-size: 14px !important;
}

.container {
  max-width: 88% !important;
}

.font-Titillium,
.mat-slide-toggle-content,
.mat-input-element {
  font-family: 'Titillium Web', sans-serif !important;
}

.no-focus:focus {
  outline: none !important;
}

.mat-paginator-container {
  font-family: 'Titillium Web', sans-serif;
  font-size: inherit;
}

.mat-paginator-icon {
  border-radius: 20px !important;
  background-color: #00275c !important;
  color: white;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #00275c;
}

mat-form-field {
  max-width: 95%;
  font-family: 'Titillium Web', sans-serif !important;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.center {
  text-align: center;
}

.mat-form-field-underline {
  background-color: #00275c !important;
}

.mat-paginator-container {
  font-family: 'Titillium Web', sans-serif;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00275c;
}

.mat-form-field-ripple {
  background-color: #e41134;
}

.mat-option-text {
  font-family: 'Titillium Web', sans-serif !important;
}

/*CHECKBOX*/
.mat-checkbox-frame {
  border-color: #e41134;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e41134;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}

/*BUTTON CUSTOM */
.btn-custom {
  border-radius: 20px;
  border: 0;
  background-color: #00275c;
  color: #fcfcfc;
  font-size: 12px;
  padding-left: 28px;
  padding-right: 28px;
  font-family: 'Titillium Web', sans-serif;
  width: 130px;
  height: 34px;
}

.btn-custom:active,
.btn-custom:hover {
  background-color: #b5985a !important;
  outline: none !important;
}

.btn-custom:focus {
  outline: none !important;
}

.white-button {
  background-color: #fcfcfc;
  color: #00275c;
}
.white-button:hover {
  background-color: #eaeff5 !important;
}

button:disabled,
button[disabled]:hover {
  cursor: not-allowed;
  background-color: #c3c3c399 !important;
  color: #f9f9f9 !important;
}

.important-button {
  background-color: #b5985a !important;
}

.important-button:hover {
  background-color: #b3a289 !important;
}

.icon-button {
  padding: unset;
  width: 30px !important;
  height: 30px !important;
}

.smaller-icon-button {
  padding: unset;
  width: 24px !important;
  height: 24px !important;
  line-height: 0 !important;
}

.small-back-button {
  padding: unset;
  width: 75px !important;
  height: 30px !important;
  line-height: 0 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
}

.color-blue {
  color: #00275c;
}

legend {
  font-family: 'Titillium Web', sans-serif !important;
  padding: 0 0 6px !important;
  margin-bottom: 1rem !important;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #b8b8b8;
  color: #00275c !important;
  font-weight: bold;
  font-size: 22px;
}

.small-legend {
  font-size: 14px;
  font-weight: 400;
  padding: 0 0 3px;
}

mat-panel-title,
mat-panel-description {
  font-family: 'Titillium Web', sans-serif;
}

.internal-expansion-panel {
  border-radius: unset !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
  box-shadow: none !important;
  background-color: whitesmoke;
}

mat-expansion-panel-header {
  font-family: 'Titillium Web', sans-serif;
}

.internal-expansion-panel mat-expansion-panel-header {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  height: 35px !important;
}

.internal-expansion-panel mat-panel-title {
  font-size: 15px !important;
}

.internal-expansion-panel .mat-expansion-panel-content {
  font-size: 13px !important;
}

.little-expansion-panel {
  border-radius: unset !important;
  box-shadow: none !important;
  border: 1px solid #dddddd;
}

.little-expansion-panel mat-expansion-panel-header {
  border: unset;
}

.width-90per {
  width: 90%;
}

.width-80per {
  width: 80%;
}

.width-40per {
  width: 40%;
}

.mat-dialog-title {
  margin-left: -24px !important;
  margin-right: -24px !important;
  margin-top: -24px !important;
  padding: 10px;
  text-align: center;
  background-color: #00275c;
  color: white;
  font-size: 20px !important;
  font-family: 'Titillium Web', sans-serif !important;
  height: 50px;
}

.mat-chip {
  background-color: var(--mdb-primary) !important;
  color: white !important;
  font-size: 12px;
  font-weight: unset;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: white !important;
}

.status-box {
  border: 0;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: 'Titillium Web', sans-serif;
  width: 90px;
  height: 27px;
  color: whitesmoke;
}

.running {
  background-color: #5aa55a;
}

.stopped {
  background-color: #f35b5b;
}

.text-bold {
  font-weight: bold;
}

/*FILTER CUSTOM */

.filt-custom {
  background-color: #b5985a !important;
  max-height: 35px;
}

.filters-row {
  font-size: 12px !important;
  padding-top: 15px;
}

.filters-row mat-form-field {
  max-width: 80%;
}

.filters-row form {
  width: 100%;
}

.filter-position .mat-expansion-panel label,
.filter-position .mat-expansion-panel input,
.filter-position .mat-expansion-panel mat-select,
.filter-position .mat-expansion-panel mat-form-field,
.filter-position .mat-expansion-panel p,
.filter-position .mat-expansion-panel mat-autocomplete,
.filter-position mat-option {
  font-size: 12px;
  max-width: 20em;
  padding-left: 5px;
}

.filter-position .mat-expansion-panel {
  align-content: center;
}

.filter-position .mat-expansion-panel p {
  text-align: center;
}

.filter-position .mat-expansion-panel-header {
  max-height: 35px;
}

.filter-position .mat-expansion-panel-header-title {
  max-height: 30px;
  font-size: 13px;
  margin-left: 50%;
}

span.mat-option-text {
  font-size: 12px;
  line-height: 3em;
  height: 3em;
}

.mat-option {
  height: 36px !important;
}

/* FILTERS */
.panel {
  background-color: transparent;
}

.black-panel {
  height: 100vh;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 200;
  top: 0;
}

.panel-no-submenu {
  margin-bottom: 0 !important;
}

.filter-position {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: var(--filter-position-index);
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.filter-title {
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.5em !important;
  line-height: 1.5em;
}

mat-expansion-panel span.mat-expansion-indicator {
  border: 2px solid #fff;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 5px;
  border-radius: 50px;
}

.mat-expansion-panel-header-description {
  color: #fff;
}
::ng-deep .mat-expansion-indicator {
  border: none;
}

button.mat-paginator-navigation-previous.mat-icon-button,
button.mat-paginator-navigation-next.mat-icon-button {
  background-color: transparent !important;
}

.mat-select-arrow:active,
.mat-select-arrow:hover,
.mat-select-arrow:visited {
  color: rgba(0, 0, 0, 0.54) !important;
}

/*BORDER COLORS*/
.border-bottom-orange {
  margin-bottom: 20px;
  border-bottom: 2px solid #b5985a;
  padding-bottom: 20px;
}
.small-border-bottom-blue {
  margin-bottom: 10px;
  border-bottom: 1px solid #14437e;
  padding-bottom: 3px;
}

/*BACKGROUND COLORS */
.background-blue {
  background-color: #14437e;
}
.background-whitesmoke {
  background-color: whitesmoke;
}

.background-light-blue {
  background-color: #355d90;
}

.hidden-total-page .mat-paginator-range-label {
  display: none;
}

.fit-content {
  max-width: fit-content !important;
}

h2 {
  font-family: 'Titillium Web', sans-serif !important;
  color: #14437e;
}

.warn-legend {
  padding-top: 15px;
  font-family: 'Titillium Web', sans-serif !important;
  color: #eb6200;
}

.description-legend {
  padding-top: 15px;
  font-family: 'Titillium Web', sans-serif !important;
  color: #555555;
}
.bigger-description-legend {
  color: #14437e;
  font-weight: bold;
}

.rule-edit-panel-title {
  font-weight: bold;
}
.rule-edit-panel-title i {
  margin-top: 3px;
}

.rule-edit-panel {
  border-radius: unset !important;
  border: 1px solid #dddddd;
}

.rule-edit-panel .mat-expansion-indicator {
  border: none !important;
}

.ag-header-group-cell.ag-focus-managed:after,
.ag-header-cell.ag-focus-managed:after {
  content: '';
  position: absolute;
  border-left: 2px solid whitesmoke;
  right: 0;
  height: 70%;
}

mat-toolbar {
  font-family: 'Titillium Web', sans-serif !important;
}

::ng-deep.mat-tab-label {
  font-family: 'Titillium Web', sans-serif;
}

mat-button-toggle {
  font-family: 'Titillium Web', sans-serif !important;
}
.mat-button-toggle-button:focus {
  outline: none;
}

.small-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px !important;
}

label {
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #00275c;
}

.custom-tooltip {
  border: 2px solid #00275c;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}
.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}
.custom-tooltip b {
  color: #00275c;
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.credentials-tab .mat-tab-body-content {
  overflow: unset !important;
}

#blacklist-configuration-tab-group .mat-tab-label-container {
  padding-left: 110px !important;
}

.subtitle {
  font-weight: normal;
  font-size: 18px !important;
}

.draggable-row {
  font-size: 15px;
  font-family: 'Titillium Web', sans-serif;
  margin-left: -24px !important;
  margin-right: -24px !important;
  background-color: whitesmoke;
  margin-top: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 14px;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  line-height: 1;
  cursor: grab;
}

.left-mat-slide-text {
  font-family: 'Titillium Web', sans-serif !important;
  font-weight: bold;
  color: #00275c;
  margin-right: 8px;
  position: relative;
  top: -2px;
}

.add-pricing-button {
  padding: 4px;
}

.delete-source-button {
  border-radius: 20px;
  border: 0;
  background-color: white;
  color: black;
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  min-width: 100px;
  min-height: 35px;

  &:hover {
    background-color: #dbdada;
  }
}

.generic-import-modal-component {
  mat-dialog-container {
    margin: 0 !important;
    padding: 0;

    .mat-dialog-content {
      margin: 0 !important;
      max-height: 70vh !important;
    }
  }
}

@import 'import/modal_history';
